.nav {
    .nav-item{
        .nav-link:hover,
        .nav-link:focus{
            background-color: transparent;
        }
    }

}
.navbar {
    border: $none;
    border-radius: 0;
    min-height: 43px;
    background-color: $white-navbar;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);


    &.fixed-top {
        min-height: 60px;
        padding: 0px;
        margin: 0px;
    }

    &.page-nav {
        min-height: 44px;
    }

    .navbar-brand {
        font-weight: 400;
        margin: 3px 0px;
        font-size: 22px;
        color: $black-color;
    }

    .navbar-toggler {
        width: 37px;
        height: 27px;
        vertical-align: middle;
        outline: 0;
        cursor: pointer;

        &.navbar-toggler-left {
            position: relative;
            left: 0;
            padding-left: 0;
        }

        & .navbar-toggler-bar {
            width: 3px;
            height: 3px;
            border-radius: 50%;
            margin: 0 auto;
        }

        .burger-lines {
            display: block;
            position: relative;
            background-color: #888;
            width: 24px;
            height: 2px;
            border-radius: 1px;
            margin: 4px auto;
        }
    }

    .toggled {
        .burger-lines:first-child {
            top: 6px;
            -webkit-animation: topbar-x 500ms linear 0s;
            -moz-animation: topbar-x 500ms linear 0s;
            animation: topbar-x 500ms 0s;
            -webkit-animation-fill-mode: forwards;
            -moz-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }

        .burger-lines:nth-child(2) {
            opacity: 0;
        }

        .burger-lines:nth-child(3) {
            bottom: 6px;
            -webkit-animation: bottombar-x 500ms linear 0s;
            -moz-animation: bottombar-x 500ms linear 0s;
            animation: bottombar-x 500ms 0s;
            -webkit-animation-fill-mode: forwards;
            -moz-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }
    }

    .dropdown.nav-item {
        .dropdown-toggle:after {
            margin-top: 8px;
        }
    }

    .navbar-nav {
        flex-direction: row;

        .nav-item {
            .nav-link {
                color: $primary-color;
                padding: $navbar-padding-b;
                position: relative;
                display: inline-flex;
                line-height: 1.2;

                &.nav-link-arrow {
                    color: $link-color;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 20px;
                }

                &.btn {
                    margin: $navbar-margin-a-btn;
                    padding: $padding-base-vertical $padding-base-horizontal;
                }

                &.btn-round {
                    margin: $navbar-margin-a-btn-round;
                }

                [class^="fa"] {
                    font-size: $font-size-large;
                    position: relative;
                    line-height: 10px;
                    top: 1px;
                }
            }

            &.active {
                .nav-link-arrow {
                    color: $link-disabled-color;
                    padding-bottom: 9px !important;
                    border-bottom: 3px solid $link-disabled-color;
                }
            }
        }

        .notification {
            position: absolute;
            background-color: #FB404B;
            text-align: center;
            border-radius: 10px;
            min-width: 18px;
            padding: 0 5px;
            height: 18px;
            font-size: 12px;
            color: $white-color;
            font-weight: bold;
            line-height: 18px;
            top: 0;
            left: 7px;
        }

        .dropdown-toggle:after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 5px;
            margin-top: 12px;
            vertical-align: middle;
            border-top: 4px dashed;
            border-top: 4px solid\9;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
        }
    }

    .btn-simple {
        font-size: $font-size-medium;
    }

    &.fixed {
        width: calc(100% -  calc(100% - 200px));
        right: 0;
        left: auto;
        border-radius: 0;
    }

    .nc-icon {
        font-weight: 700;
    }
}

.navbar-icons{
    &.navbar .navbar-brand{
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .navbar-nav{
        .nav-item{
            .nav-link{
                text-align: center;
                padding: $navbar-padding-a-icons;
                margin: $navbar-margin-a-icons;
            }
        }

        [class^="pe"] {
            font-size: 30px;
            position: relative;
        }
        p {
            margin: 3px 0 0;
        }
    }
}

.navbar-transparent{
    padding-top: 15px;
    background-color: transparent;
    border-bottom: 1px solid transparent;
}

.navbar-toggle{
    margin-top: 19px;
    margin-bottom: 19px;
    border: $none;

    .icon-bar {
        background-color: $white-color;
    }
     .navbar-collapse,
     .navbar-form {
        border-color: transparent;
    }

    &.navbar-default .navbar-toggle:hover,
    &.navbar-default .navbar-toggle:focus {
        background-color: transparent;
    }
}
