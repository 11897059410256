// styles.scss will override all other style sheets
// add @imports in angular.json "styles"

@import "./assets/bootstrap_light.scss";
@import "./assets/pos_variable.scss";
// if you'd like to use it without importing all of bootstrap it requires
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "./assets/toastr-bootstrap-5.scss";
@import "./assets/bootstrap-5-adjustment.scss";
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Roboto Serif', serif;
  font-weight: $font-weight-normal;
}

/*Loader*/
.dark-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  top: 5%;
  z-index: 99;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  top: 35%;
  left: 35%;
  z-index: 100;
}

.ng-dropdown-panel {
  z-index: 2100 !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.5 !important;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.cdk-overlay-container {
  z-index: 1050 !important;
}

.ng-select.tall .ng-select-container {
  min-height: 3.625rem !important;
}

.ng-select.tall.ng-select-single .ng-select-container {
  height: 3.625rem !important;
}

.ng-select.tall.center-text input {
  padding-top: 0.8rem !important;
}

mat-dialog-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 12px !important;
}

//Change mat-radio button color : https://stackoverflow.com/questions/45368434/angular-material-change-color-of-md-radio-button/45370257
//Needs !important for ripple effect or it won't apply
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #89b03e !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #89b03e;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #89b03e;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #d0e6a6 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #89b03e !important;
}

//Change mat-checkbox color : https://stackoverflow.com/questions/54168627/mat-checkbox-custom-color-cannot-be-changed
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-ripple-element {
  background-color: #89b03e !important;
}

.default {
  cursor: default;
}

.card.product {
  margin-bottom: 0.625rem !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg-err {
  background-image: repeating-linear-gradient(
    -60deg,
    #f8f6d7,
    #f8f6d7 5%,
    #f7f081 5%,
    #f7f081 10%
  );
}

.over-due {
  background: repeating-linear-gradient(
    -60deg,
    #f8d7da,
    #f8d7da 15px,
    #f5c6cb 15px,
    #f5c6cb 30px
  ) !important;
}
