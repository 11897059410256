$fl_borderFocus: #1A73E8;
$fl_border: #c8d3df;
$fl_labelFocus: #3F8AEC;
$fl_labelEmpty: #cccccc;
$fl_label: #9DA2A6;
$fl_input: #343434;

.form-label-group {
    position: relative;
    margin-bottom: 1rem;

    > {

        input {
            height: 3.625rem;
            padding: .75rem;
            color: $fl_input;
            font-size: 0.875rem;
            border-radius: 0.25rem !important;
            border-color: $fl_border;

            &:focus {
                border: solid 1.5px $fl_borderFocus !important;
            }
        }

        label {
            width: auto;
            position: absolute;
            top: 1.125rem;
            left: 0.75rem;
            display: block;
            margin-bottom: 0;
            /* Override default `<label>` margin */
            line-height: 1.5;
            color: $fl_labelEmpty;
            pointer-events: none;
            cursor: text;
            /* Match the input under the label */
            border: 1px solid transparent;
            border-radius: .25rem;
            transition: all .1s ease-in-out;
            font-size: 0.875rem;
        }

        textarea {
            padding: .75rem;
            color: $fl_input;
            resize: both;
            font-size: 0.875rem;
            border-radius: 0.25rem;
            border-color: $fl_border;

            ~label {
                top: 0.9375rem;
            }

            &:focus {
                border: solid 1.5px $fl_borderFocus !important;

                ~label {
                    top: 1rem;
                    left: 0.78125rem;
                }
            }
        }

        // For ng-select (single) add  [ngClass]="{'empty' : Constants.IsEmpty(ngModel in select)}"
        // For ng-select (multiple) add  [ngClass]="{'empty' : Constants.IsEmpty( (ngModel in select).length == 0)}" and add class multiple.
        ng-select ::ng-deep {
            .ng-select-container {
                min-height: 3.625rem;
                border-color: $fl_border;
                box-shadow: none;
            }
        }

        ng-select {
            ~label {
                display: inline-block;
                top: -0.625rem;
                background-color: white;
                font-size: 0.75rem !important;
                color: $fl_label;
                padding: 0 0.25rem;
                z-index: 2000;
                left: 0.5rem;
            }
        }
    }

    &.multiple {
        ng-select ::ng-deep {
            .ng-input {
                padding-left: 0.25rem !important;
            }

            .ng-placeholder {
                top: 18px !important;
            }
        }
    }

    &.radio {
        height: 3.625rem;
        padding: .75rem;
        color: $fl_input;
        border-radius: 0.25rem !important;
        border: solid 1px $fl_border;

        label {
            background-color: white;
            font-size: 0.75rem !important;
            color: $fl_label;
            top: -10px !important;
            padding: 0 0.25rem;
            left: 0.5rem;
        }
    }

    &.empty {
        ng-select {
            ~label {
                display: none;
            }
        }
    }

    input,
    textarea {

        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder,
        &::-moz-placeholder,
        &::placeholder {
            color: transparent;
        }
    }

    input,
    textarea {
        &:not(:placeholder-shown) {
            ~label {
                background-color: white;
                font-size: 0.75rem !important;
                color: $fl_label;
                top: -10px !important;
                padding: 0 0.25rem;
                left: 0.5rem;
            }

            &:focus {
                ~label {
                    color: $fl_labelFocus;
                }
            }
        }
    }


    ng-select ::ng-deep {
        .ng-placeholder {
            padding-left: 0.25rem;
            color: $fl_labelEmpty !important;
            font-size: 0.875rem;
        }

        .ng-has-value {
            .ng-value {
                // position: absolute;
                padding-left: 0.25rem;
                font-size: 0.875rem;
            }
        }

        .ng-value-container {
            overflow: initial !important;
        }

        .ng-input {
            top: auto !important;
            padding-left: 0.875rem !important;
            font-size: 0.875rem;
        }
    }
}
