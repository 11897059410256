.sidebar,
body > .navbar-collapse{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    display: block;
    z-index: 1;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    background-position: center center;

    .sidebar-wrapper{
        position: relative;
        max-height: calc(100vh - 61px);
        min-height: calc(100vh - 61px);
        overflow: auto;
        width: 200px;
        z-index: 4;
        
    }
    
/*
    ------ @Scrollbar styling
*/
.sidebar-wrapper {
    overflow: hidden;
    cursor: pointer;
   
}

.sidebar-wrapper:hover {
    overflow-y: auto !important;
}

.sidebar-wrapper::-webkit-scrollbar {
    padding-right: 20px;
    width: 7px !important;
}

.sidebar-wrapper::-webkit-scrollbar-button {
    width: 1px;
    height: 30px;
}

.sidebar-wrapper::-webkit-scrollbar-track {
    border-left: 1px solid white;
    width: 8px;
}

.sidebar-wrapper::-webkit-scrollbar-thumb {
    border-left: 3px solid white;
}

    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
    }

    .logo{
        height:60px;
        padding: 10px 15px 9px 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        position: relative;
        z-index: 5;

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            padding: $padding-small-vertical $padding-zero;
            display: block;
            font-size: $font-size-large;
            color: $white-color;
            text-align: center;
            font-weight: $font-weight-normal;
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    .nav{
        margin-top: 10px;
        float: none;
        display: block;

        li{
            .nav-link{
                color: $white-color;
                margin: 5px 15px;
                opacity: .86;
                border-radius: 4px;
                display: block;
                padding: 10px 15px;

                &:hover{
                    background: rgba(255, 255, 255, 0.13);
                    opacity: 1;
                }

                p{
                    margin: 0;
                    line-height: 31px;
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    display: inline-flex;
                }

                i{
                    font-size: 20px;
                    margin-right: 15px;
                    width: 30px;
                    text-align: center;
                    vertical-align: middle;
                    float: left;
                    line-height: 30px;
                }
            }

            &:hover .nav-link{
                background: rgba(255,255,255,0.13);
                opacity: 1;
            }

            &.active .nav-link{
                color: $white-color;
                opacity: 1;
                background: rgba(255,255,255,0.23);

            }

            &.separator{
                margin: 15px 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                & + .nav-item {
                    margin-top: 31px;
                }
            }

        }



        .caret{
            margin-top: 13px;
            position: absolute;
            right: 30px;
        }

        .active-pro{
            position: absolute;
            width: 100%;
            bottom: 10px;

            a{
                color: $white-color !important;

            }
        }

        .nav-link{
            color: $white-color;
            margin: 5px 15px;
            opacity: .86;
            border-radius: 4px;
            text-transform: uppercase;
            line-height: 30px;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .logo{
        padding: $navbar-padding-a;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            padding: 5px 0 5px 15px;
            display: block;
            font-size: $font-size-large;
            color: $white-color;
            text-align: center;
            font-weight: $font-weight-normal;
            line-height: 30px;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    &:after,
    &:before{
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &:before{
        opacity: .33;
        background: #000000;
    }

    &:after{
       @include icon-gradient($new-purple, $purple-color-bottom);
       z-index: 3;
       opacity: 1;
    }

    &[data-image]:after,
    &.has-image:after{
        opacity: .77;
    }

    &[data-color="black"]:after{
        @include icon-gradient($default-states-color, $default-states-color);
    }
    &[data-color="blue"]:after{
        @include icon-gradient($new-dark-blue, $blue-color-bottom);
    }
    &[data-color="azure"]:after{
        @include icon-gradient($new-blue, $azure-color-bottom);
    }
    &[data-color="green"]:after{
        @include icon-gradient($new-green, $green-color-bottom);
    }
    &[data-color="orange"]:after{
        @include icon-gradient($new-orange, $orange-color-bottom);
    }
    &[data-color="red"]:after{
        @include icon-gradient($new-red, $red-color-bottom);
    }
    &[data-color="purple"]:after{
        @include icon-gradient($new-purple, $purple-color-bottom);
    }
}


.main-panel {
    padding: 60px 0 0 200px;
    background: white;

    .main-panel-content {
        min-height: calc(100% - 123px);
        padding-bottom: 60px;
    }

    > .footer {
        border-top: 1px solid #e7e7e7;
    }

    .navbar {
        margin-bottom: 0;
    }
}

.sidebar,
.main-panel{
    -webkit-transition-property: top,bottom;
    transition-property: top,bottom;
    -webkit-transition-duration: .2s,.2s;
    transition-duration: .2s,.2s;
    -webkit-transition-timing-function: linear,linear;
    transition-timing-function: linear,linear;
    -webkit-overflow-scrolling: touch;
}


@include media-breakpoint-up(lg) {
    .main-panel .navbar.fixed-top {
        left: 200px !important;
    }
}
