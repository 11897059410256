/*General*/
$header-color: #3B3B3B; //pos variable //black color for all header , h1-h6, th panel header
$body-color: #333333; //black color for all content
$white: #ffffff; // rewrite for easy import to other file
$light:#dddddd; //border color
$green: #89b03e;//color for success
$dark: #4d4d4d; //color for specific dark (card header)
$blue: #337ab7 ;//primary blue for all link
$light-blue: #dfeffc ;//modal button
$gray-secondary: #cccccc;
$red: #c9302c;
$orange: #f6a828 ;



$lightgrey: rgba(203, 203, 210, 0.15);//pos variable

$font-family-base: 'Roboto', sans-serif;
$headings-font-family: 'Roboto Slab', serif;

$text-muted: #9a9a9a ;
$link-color :#0976b4;

$h1-font-size: 1.375rem; //22px
$h2-font-size: 1.25rem; //20px
$h3-font-size: 1.125rem; //18px
$h4-font-size: 1rem; //16px
$h5-font-size: 0.875rem; //14px
$h6-font-size: 0.8125rem; //13px
$font-size-base: 0.75rem; //12px
$font-size-sm: 0.625rem ;



$headings-font-weight: 700;
$font-weight-normal: 400;
//primary:  all link
//success : main button
//secondary : secondary button
//nav-blue
$theme-colors: ( "primary":$blue, "success": $green, secondary:#cccccc, "dark": $dark,"light":$light,"light-gray":$lightgrey,"light-blue":$light-blue,"danger":$red,"warning":$orange);

$spacer: 0.125rem; //2px spacing for each level
$spacers: ( 1: $spacer, 2: $spacer*2, 3: $spacer*3, 4:$spacer*4,5:$spacer*5,6:$spacer*6,7:$spacer*7,8:$spacer*8,9:$spacer*9,10:$spacer*10,11:$spacer*11,12:$spacer*12 );

//table
$table-head-color: $header-color;
$table-head-bg: #f6f6f6;
$table-border-color: $light;
$table-cell-padding: 12px 8px;

//btn
$btn-padding-y: 0.625rem;
$btn-padding-x: 0.9375rem;


//drop down
$dropdown-item-padding-y: .5rem;
$dropdown-item-padding-x: 1rem;
$dropdown-link-color: $body-color;
$dropdown-link-active-bg: transparent;
$dropdown-link-active-color: inherit;
//form
$input-focus-box-shadow: none;
$input-border-radius:0px;
$input-border-radius-lg: 1px;
$font-size-lg:1rem;
$input-group-addon-bg: white;

$modal-input-border: 1px solid #C8D3DF;
$modal-input-padding:5px 7px;
//card
$card-cap-bg: $white !default;
$card-spacer-x: 0.9375rem;

//border
$border:1px solid $light;

//Receipt Printing
$enable-print-styles: false;



