@import "pos_variable";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "light-bootstrap-dashboard";

$fl_borderFocus: #1A73E8;
$fl_border: #c8d3df;
$fl_labelFocus: #3F8AEC;
$fl_labelEmpty: #cccccc;
$fl_label: #9DA2A6;
$fl_input: #343434;

//Gradient Backgrounds, use last class name only
.over-due, .bg-gradient-danger {
    background: repeating-linear-gradient(-60deg, #f8d7da, #f8d7da 15px, #f5c6cb 15px, #f5c6cb 30px) !important;
}

.bg-gradient-success {
    background: repeating-linear-gradient(-60deg,#28a745,#28a745 15px,#a2dbb0 15px,#a2dbb0 30px) !important;
}

.date-warning, .over-due-today, .bg-gradient-warning {
    background: repeating-linear-gradient(-60deg,#FCF3CF,#FCF3CF 15px,#F9E79F 15px,#F9E79F 30px) !important;
}

.info-label-group {
    position: relative;
    margin-bottom: 1rem;
    min-height: 3.625rem;
    padding: .375rem .75rem;
    border: 1px solid $fl_border;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;

    label {
        position: absolute;
        color: $fl_label;
        margin-bottom: 0;
        top: -0.5625rem;
        padding: 0 0.25rem;
        left: 0.5rem;
        background-color: white;
    }

    div {
        color: $fl_input;
        font-size: 0.875rem;
    }

    &.empty {
        label {
            position: static;
            padding: 0;
            font-size: 0.875rem;
            color: $fl_labelEmpty;
        }
    }
}



.form-label-group {
    position: relative;
    margin-bottom: 1rem;

    > {

        input {
            height: 3.625rem;
            padding: .75rem !important;
            color: $fl_input;
            font-size: 0.875rem;
            border-radius: 0.25rem !important;

            &:focus {
                border: solid 1.5px $fl_borderFocus !important;
            }
        }

        input.ng-invalid.ng-touched, input.invalid {
            border-color: #fb404b;
        }

        label {
            width: auto;
            position: absolute;
            top: 1.125rem;
            left: 0.75rem;
            display: block;
            margin-bottom: 0;
            /* Override default `<label>` margin */
            line-height: 1.5;
            color: $fl_labelEmpty;
            pointer-events: none;
            cursor: text;
            /* Match the input under the label */
            border: 1px solid transparent;
            border-radius: .25rem;
            transition: all .1s ease-in-out;
            font-size: 0.875rem;
        }

        textarea {
            padding: .75rem !important;
            color: $fl_input;
            resize: both;
            font-size: 0.875rem;
            border-radius: 0.25rem;

            ~ label {
                top: 0.75rem;
            }

            &:focus {
                border: solid 1.5px $fl_borderFocus;

                ~ label {
                    top: 0.78125rem;
                }
            }
        }

        .select2-container {
            .select2-selection {
                height: 3.625rem !important;
                background: white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-color: $fl_border;
                box-shadow: none;
                border-radius: 0.25rem !important;
                font-size: 0.875rem !important;

                .select2-selection__arrow {
                    position: relative;
                    top: -1px;
                }
            }

            .select2-selection__placeholder {
                padding-left: 0.25rem;
            }
        }
    }

    &.radio {
        height: 3.625rem;
        padding: .75rem;
        border-color: $fl_border !important;

        > label {
            top: -0.5625rem;
            font-size: 0.75rem;
            color: $fl_label;
            left: 0.5rem;
            padding: 0 0.25rem;
            background-color: white;
        }

        ul {
            padding-top: 10px;
        }
    }

    &.selected {
        label {
            top: -0.5rem;
            font-size: 0.75rem;
            color: $fl_label;
            left: 0.5rem;
            padding: 0 0.25rem;
            background-color: white;
        }

        .select2-selection__rendered {
            position: relative;
            display: flex !important;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            padding-right: 0.25rem !important;
            padding-left: 0.75rem !important;

            .select2-selection__clear {
                float: none !important;
            }
        }
    }
    //use this class if allowClear is false
    &.flexRow {
        .select2-selection__rendered {
            flex-direction: row !important;
        }
    }

    &.multiple {
        .select2-selection__rendered {
            flex-direction: row !important;
            justify-content: initial;
            padding: 0 0.75rem !important;
        }

        .select2-container--focus {
            .select2-selection--multiple {
                border: solid 1.5px $fl_borderFocus !important;
            }

            .select2-search {
                margin-top: 0.75rem;

                ::placeholder {
                    visibility: hidden;
                }
            }

            ~ label {
                top: -0.5rem;
                font-size: 0.75rem;
                color: $fl_labelFocus;
                left: 0.5rem;
                padding: 0 0.25rem;
                background-color: white;
            }
        }

        .select2-search__field {
            margin-top: 0 !important;
        }

        &.selected {
            .select2-search__field {
                margin-top: 0.3125rem !important;
            }

            .select2-selection__rendered {
                overflow: auto;
            }

            .select2-search {
                margin-top: 0;
            }

            label {
                top: -0.5rem;
            }
        }
    }

    > input, > textarea {
        &::placeholder {
            color: transparent;
        }

        &::-ms-input-placeholder {
            color: transparent;
        }
    }

    input, textarea {
        &:not(:placeholder-shown) {
            ~ label {
                background-color: white;
                font-size: 0.75rem !important;
                color: $fl_label;
                top: -10px !important;
                padding: 0 0.25rem;
                left: 0.5rem;
            }

            &:focus {
                ~ label {
                    color: $fl_labelFocus;
                }
            }
        }
    }




    &.bottom {
        input {
            border-radius: 0rem;
            border-top: none;
            border-right: none;
            border-left: none;
            padding-left: 0.25rem;
            padding-right: 0.25rem;

            &:focus {
                border-top: none !important;
                border-right: none !important;
                border-left: none !important;
            }
        }

        label {
            padding-left: 0.1875rem;
            padding-right: 0.25rem;
            top: 6.5px;
        }
    }
}


.sidebar {
    z-index: 1021 !important;
}

.product-inventory {
    margin-bottom: 46px;
    height: calc(100% - 220px);
    overflow: auto;
}

.tooltip {
    pointer-events: none;
}

.sticky-top-thead {
    tr {
        border: none !important;
    }

    th {
        position: sticky;
        top: 60px;
        z-index: 1020;
    }

    th::after,
    th::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        z-index: -1;
    }

    th:first-child::before {
        height: 100%;
        border-left: 1px solid #ddd;
    }

    th:last-child::after {
        height: 100%;
        border-right: 1px solid #ddd;
    }

    th::before {
        top: 0px;
        border-top: 1px solid #ddd;
    }

    th::after {
        bottom: -1px;
        border-bottom: 1px solid #ddd;
    }
}



ul.ui-menu.ui-autocomplete {
    max-height: 250px;
    overflow-y: auto;
}

.fixed-top {
    background: white;
}

.w-0 {
    width: 0 !important;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
}

.text-primary {
    color: #0976b4 !important;
}

.text-danger {
    color: #fb404b !important;
}

.text-dark {
    color: #5e5e5e !important;
}

.text-success {
    color: #87cb16 !important;
}

.text-black {
    color: black !important;
}

.bg-low-danger {
    background-color: #f2dede !important;
}


.headings-font-family {
    font-family: $headings-font-family;
}

b,
strong {
    font-weight: $headings-font-weight;
}

a:hover {
    text-decoration: none;
}
//custom more menu
.sidebar .nav li .nav-link, body > .navbar-collapse .nav li .nav-link {
    padding: 10px 0 10px 10px !important;
}

html, body {
    height: 100%;
}

.sidebar-wrapper {
    max-height: calc(100% - 61px) !important;
    min-height: calc(100% - 61px) !important;
    scrollbar-width: thin;
}

.navbar .navbar-brand {
    white-space: nowrap;
    max-width: 24.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

div#divSideMenu {
    position: absolute;
    right: 20px;
    top: 74px;
}

.dropdown-header {
    font-size: 0.6875rem !important;
}

.dropdown .dropdown-menu.show {
    top: 100% !important;
    transform: none !important;
    z-index: 10000;
    cursor: pointer;

    > div.d-flex {
        flex-direction: row !important;
    }
}

.pointer {
    cursor: pointer;
}


.dropdown.text-secondary a i:hover {
    color: #23CCEF !important;
}

.dropdown-menu-end {
    right: 0px !important;
    left: auto !important;
}

.sub-dropdown {
    margin-top: -34px;
    left: -160px;
}

.dropdown-item a {
    color: $color-contrast-dark;
    cursor: pointer;
    display: block;
}
/*Custom Action*/
#action {
    margin-top: 59px; //mobile version only
}
/*action button*/
.btn {
    font-size: 14px;
}

.btn-success {
    font-family: $head-font-family;
    color: $white !important;
}

.btn-outline-secondary {
    color: $body-color;
}

#action-button {
    > .btn, > .btn-group {
        margin-left: .625rem;

        .fas, .far {
            margin-right: .3125rem;
        }
    }
}
/*Table*/
.table {
    thead th {
        text-transform: uppercase;
        border: none;
        vertical-align: middle;
    }

    thead tr {
        border: 1px solid #dddddd !important;
    }


    th, td {
        vertical-align: middle;
        line-height: 25px;
    }
    .thead-light th{
        color:#3b3b3b;
        background-color:#f6f6f6;
        border-color:#ddd;
    }
}

p.special-TinyMCE > p {
    margin-bottom: .125rem !important;
}

small > p {
    margin-bottom: .125rem !important;
}
/*Zebra*/
div.zebra {
    display: flex;
    border-collapse: collapse;
}

div.zebra > div {
    flex-flow: row wrap;
}

div.zebra > div > strong {
    display: block;
    border: 1px solid #525252;
    background-color: #545454;
    background-image: -webkit-gradient(linear,50% 0,50% 100%,color-stop(0%,#888),color-stop(100%,#545454));
    background-image: -webkit-linear-gradient(#888,#545454);
    background-image: -moz-linear-gradient(#888,#545454);
    background-image: -o-linear-gradient(#888,#545454);
    background-image: linear-gradient(#888,#545454);
    -webkit-box-shadow: inset 0 2px 0 -1px rgba(255,255,255,0.2);
    -moz-box-shadow: inset 0 2px 0 -1px rgba(255,255,255,0.2);
    box-shadow: inset 0 2px 0 -1px rgba(255,255,255,0.2);
    text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
    height: 28px;
    border-bottom: 1px solid #333;
    padding: 2px 7px;
    color: white;
    font-weight: bold;
}

div.zebra > div > span {
    display: block;
    padding: 5px 7px;
    border: solid 1px #d8d8d8;
    font-weight: bold;
}

div.zebra > div > span.number {
    text-align: right;
}
/*grid*/
.main-panel-content > .container-fluid {
    padding-top: 10px;
}
/*card*/
.card {
    margin-bottom: 30px;
}

.card-header {
    border-bottom: none;
    padding-top: $card-spacer-x;
    padding-bottom: 0px;
    padding-left: $spacer * 9;

    h2, h3 {
        font-weight: $font-weight-light;
        color: $dark;
        margin: 0px;

        a {
            color: $dark;
        }
    }
}
/*Sorter*/
.sortable {
    background-image: url(//res.cloudinary.com/pos/image/upload/POS/Images/Plugin/Tablesorter/arrow-black.png);
    cursor: pointer;
    background-position: top -36px right 5px;
    background-repeat: no-repeat;
    padding-right: 16px !important;
    outline: none;
}

.sortable-asc {
    background-position: top -7px right 5px;
}

.sortable-desc {
    background-position: top 18px right 5px;
}
/*Select 2*/
.select2-container--default .select2-selection--single {
    height: 32px !important;
    border-radius: 2px !important;
    outline: 0;
    border-color: #B7B7B7;
    background-color: #fff;
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #ffffff), color-stop(50%, #f6f6f6), color-stop(52%, #eeeeee), color-stop(100%, #f4f4f4));
    background: -webkit-linear-gradient(to bottom, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
    background: -moz-linear-gradient(to bottom, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
    background: -o-linear-gradient(to bottom, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
    background: linear-gradient(to bottom, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
    background-clip: padding-box;
    box-shadow: 0 0 3px white inset, 0 1px 1px rgba(0, 0, 0, 0.1);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-top: 2px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    font-size: 16px;
}

.select2-results__option {
    padding: 6px 10px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: 0 !important;
}

.barcode_counter, .member_input {
    position: fixed;
    right: 10px;
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #eeeeee));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
    /* W3C */
    border: 1px solid #ddd;
    padding: 8px 10px;
    border-radius: 3px;
}

.barcode_counter input, .member_input input {
    padding: 4px 8px 3px 5px;
    border-radius: 3px;
    border: 1px solid #B7B7B7;
    background-repeat: no-repeat;
    background-position: 5px -211px;
    padding: 4px 5px 4px 35px;
}

.barcode_counter .icon, .member_input .icon {
    position: relative;
    left: 35px;
    vertical-align: middle;
}

.barcode_counter span, .member_input span {
    margin-right: 5px;
    color: #868686;
    left: 30px;
    position: relative;
    margin-left: -25px;
}

.barcode_counter {
    bottom: 20px;
}
/* Filter */
search-box {
    .dropdown-menu {
        border-radius: 4px !important;
        margin: -5px 0 0 0 !important;
        padding: 10px;

        div.d-flex > div:not(:last-child) {
            margin-right: 10px;
        }

        div[container] {
            width: 200px;

            > div {
                margin: 0 0 12px 0;

                > h6 {
                    margin-bottom: 5px;
                }

                > input, > select {
                    width: 100%;
                    padding: 7px 5px 6px 5px;
                    border: solid 1px #ddd;
                }

                > input[datepicker] {
                    width: 95px;
                }

                radioyesnob {
                    ul {
                        margin: 10px 0 0 0;
                    }
                }
            }
        }

        .more {
            background-color: white;
            position: absolute;
            bottom: -15px;
            width: 5rem;
        }
    }
}

search-box .dropdown-menu div[container] > div > h6 {
    font-size: 12px;
}
/* More filter */
.more {
    -moz-box-shadow: inset 0 5px 5px -5px rgba(0,0,0,0.25);
    -webkit-box-shadow: inset 0 5px 5px -5px rgba(0,0,0,0.25);
    box-shadow: inset 0 5px 5px -5px rgba(0,0,0,0.25);
    -moz-border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
    border: $border;
    padding: 0 15px 0 10px;
    color: #666;
    display: inline;
    height: 20px;
    font-size: 10px;
    text-transform: uppercase;
    text-decoration: none;
    width: auto;
    cursor: pointer;
    outline: none;
}
//custom toogle checkbox
.checkbox_toggle {
    position: relative;

    span {
        color: #868686;
        display: inline-block;
        padding-right: 38px;
    }

    label {
        width: 32px;
        height: 18px;
        position: absolute;
        background-color: $gray-600;
        top: 0px;
        right: 0;
        border-radius: 50px;
        cursor: pointer;
    }

    input[type="checkbox"] {
        display: none;

        &:checked {
            + label {
                background-color: $green;
            }

            + label:after {
                left: 15px;
            }
        }
    }

    label:after {
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 1px;
        transition: all 0.1s;
        background-color: $white;
    }
}
/*Date picker*/
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    color: $body-color;
    cursor: pointer;
}
//input
.form-control:focus {
    border: $input-border-width solid $input-border-color;
}

search-box .form-control {
    height: auto;
}

.input-group {
    > .input-group-prepend + input {
        border-left: none !important;
        padding-left: 0px;
    }
}
//modal
.modal-header, .modal-footer, .modal-footer .btn {
    padding: 15px;
}

.modal-dialog {
    max-width: none;
}

.modal-content {
    width: auto;
    margin: auto;
    max-width: 850px;
}

.maxHeight .modal-body {
    max-height: 400px;
    overflow: auto;
}

.modal-body {
    strong {
        display: block;
    }

    .more {
        margin: 0;
        position: absolute;
        bottom: -20px;
    }

    input[type="text"], textarea, input[type="password"] {
        border: $modal-input-border;
        padding: $modal-input-padding;
    }

    table textarea {
        width: 330px;
        height: 90px;
    }
}
//cursor
[data-toggle="modal"] {
    cursor: pointer;
}
//park-order
.park-order {
    &:hover {
        .card-header {
            background-color: $green !important;
        }
    }

    .card {
        .card-header {
            background-color: #b1aaaa;
        }
    }
}
//loading
/*overlay*/
.loading-overlay {
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    cursor: wait;
}

.loading-overlay {
    display: none;
}

.loading-block {
    z-index: 10000;
    position: fixed;
    padding: 50px;
    margin: 0px;
    width: 395px;
    top: 40%;
    left: 35%;
    text-align: center;
    color: rgb(0, 0, 0);
    border: 1px solid rgb(170, 170, 170);
    cursor: wait;
    box-shadow: rgb(136, 136, 136) 0px 0px 4px;
    opacity: 1;
    background-color: rgb(255, 255, 255);
}

small.h6 {
    line-height: 0,2;
}
/*num pad*/
table.nmpd-grid {
    position: fixed;
    top: calc(50% - 150px);
    right: 10px;
    z-index: 10001;
    border-radius: 5px;
    padding: 5px;
    width: initial;
    background: #f4f4f4;
    border: $border;
}

.nmpd-grid td {
    padding: 3px;
}

.nmpd-grid a {
    border: none;
    background: $white;
    font-weight: $font-weight-normal;
    font-family: $head-font-family;
    color: $blue;
    font-size: 24px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    height: 80px;
    width: 80px;
}

.nmpd-grid td[colspan] a {
    width: 166px;
}

.form-control[readonly] {
    background-color: white;
}

.form-control::-webkit-input-placeholder {
    color: #cccccc;
}

.form-control:-moz-placeholder {
    color: #cccccc;
}

.form-control::-moz-placeholder {
    color: #cccccc;
}

.tab-pane {
    margin-top: 1.5rem;
}

.strike {
    text-decoration: line-through;
}


@media (max-width: 468px) {
    .navbar .navbar-brand {
        white-space: nowrap;
        max-width: 7em;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 480px) {
    search-box {
        .dropdown-menu {
            margin: 0 !important;
            right: 0;
            left: auto;
        }
    }

    .navbar.page-nav {
        height: auto;
    }

    img {
        border: 1px solid #dee2e6 !important;
    }
}

@media (max-width: 656px) {
    .dropdown .dropdown-menu.show {
        > div.d-flex {
            flex-direction: column !important;
        }
    }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.list-inline span {
    display: inline-block;
    bottom: 2px;
    position: relative;
    font-weight: normal;
}
//subscription
div.contactus > div {
    background-image: url(//res.cloudinary.com/pos/image/upload/POS/Images/MySubscription/dialog_subscribe_icon.png);
    background-repeat: no-repeat;
    padding-left: 100px;
    height: 90px;
}

.cursor {
    cursor: pointer;
}


@media print {
    html, body {
        height: auto;
    }

    body {
        color: black !important;
    }

    .loading-overlay {
        display: none;
    }

    #toast-container {
        display: none;
    }

    .text-dark {
        color: black !important;
    }


    .sidebar, .navbar.page-nav, .d-lg-flex {
        display: none !important;
        border-bottom: none !important;
    }

    .loading-block {
        display: none;
    }

    .navbar .navbar-brand {
        max-width: 50rem;
    }

    .navbar .navbar-nav {
        display: none;
    }

    iframe {
        display: none !important;
    }
}

@media (max-width: 991px) {
    /*Make scrollbar always visible on mobile menu*/
    .sidebar-wrapper {
        overflow-y: scroll !important;
    }

    .sticky-top-thead th {
        top: 0px;
    }
}

@media (pointer: coarse) {
    /*Make scrollbar always visible on touchscreen device*/
    .sidebar-wrapper {
        overflow-y: scroll !important;
    }
}

@media(min-width:1366px) {
    .navbar.page-nav {
        height: 44px;
    }
}

input:focus, textarea:focus, select:focus {
    outline: none !important;
}




@media print {

    .text-success, .text-info, .text-danger {
        color: black !important;
    }

    .border-top, .border-bottom {
        border-color: #b3aaaa !important;
    }
}
//toast
.toaster {
    position: fixed;
    bottom: 20px;
    right: 15px;
    z-index: 2147483005;
}

.tooltip {
    font-size: 12px;
}
