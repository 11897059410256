.p-0 {
  padding: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.me-0 {
  margin-right: 0 !important;
}
.mt-0 {
  margin-top: 0;
}
.ps-0 {
  padding-left: 0 !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}

div#divSideMenu {
  top: 60px !important;
  border-bottom: 0 !important;
  background: transparent;
}

.btn-block {
  display: block !important;
  width: 100% !important;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #ffffff !important;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info {
  color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.g-0 {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.btn-link {
  text-decoration: none;
}

.modal-body {
  padding: 1rem;
}

.btn-close {
  opacity: 1;
}

.card-body {
  padding: 0.9375rem;
}

a {
  text-decoration: none;
}

.alert {
  padding: 0.75rem 1.25rem;
}

.table-light,
.table-light > th,
.table-light > td {
  --bs-table-bg: whitesmoke;
}

.btn-light-blue {
  color: #212529;
  background-color: #dfeffc;
  border-color: #dfeffc;
}
.btn-light-blue:hover {
  color: #212529;
  background-color: #bcdef9;
  border-color: #b0d8f8;
}
.btn-light-blue:focus,
.btn-light-blue.focus {
  color: #212529;
  background-color: #bcdef9;
  border-color: #b0d8f8;
  box-shadow: 0 0 0 0.2rem rgba(195, 209, 220, 0.5);
}
.btn-light-blue.disabled,
.btn-light-blue:disabled {
  color: #212529;
  background-color: #dfeffc;
  border-color: #dfeffc;
}
.btn-light-blue:not(:disabled):not(.disabled):active,
.btn-light-blue:not(:disabled):not(.disabled).active,
.show > .btn-light-blue.dropdown-toggle {
  color: #212529;
  background-color: #b0d8f8;
  border-color: #a5d2f7;
}
.btn-light-blue:not(:disabled):not(.disabled):active:focus,
.btn-light-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-light-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 209, 220, 0.5);
}

.bg-light-gray {
  background-color: rgba(203, 203, 210, 0.15) !important;
}

.table > :not(:first-child) {
  border-top: none;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem !important;
}
